/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  Snackbar,
  Typography,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import {
  fetchTaxProfile,
  editTaxProfile,
  deleteTaxProfile,
} from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import {
  AvenirRedH4,
  AvenirBlackH4,
  H4,
  H8,
  AvenirBlackH3,
} from "../../../../utils/text";
import styles from "../../../../styles/index";
import { validateText } from "../../../../utils/util";
import DeleteIcon from "../../../../components/SVG/Delete";
import {
  Settings as SettingsIcon,
  CircleClose,
  Circle as CircleIcon,
} from "../../../../components/SVG";
import {
  CategoryDropDownList,
  CustomCheckbox,
  InputText,
  RightDrawer,
  MultiSelect,
} from "../../../../components";
import AlertDialogDelete from "../../../../components/AlertDialogDelete";

const EditTaxProfile = ({
  open,
  row,
  editcallback,
  onClose,
  deleteCallback,
}) => {
  const style = styles();
  const dispatch = useDispatch();
  const taxList = useSelector((state) => state.taxConfigurationReducer.taxes);
  const taxProfileList = useSelector(
    (state) => state.taxConfigurationReducer.taxProfiles
  );
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const taxProfileAssociations = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileAssociations
  );
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [comment, setComment] = useState("");
  const Context = useContext(MyContext);
  const [taxesArray, setTaxesArray] = useState([]);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const [taxItemsArray, setTaxItemsArray] = useState([]);
  const [specialRulesFlag, setSpecialRulesFlag] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [maxAmountErrors, setMaxAmountErrors] = useState({});
  const [minQuantityErrors, setMinQuantityErrors] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const [isDeleteError, setIsDeleteError] = useState(true);
  const [deleteHeading, setDeleteHeading] = useState(
    Context.langData.cannot_delete
  );
  const [deleteBodyMessage, setDeleteBodyMessage] = useState(
    Context.langData.cannot_delete_tax_profile_body
  );

  useEffect(() => {
    if (row.id) {
      if (!row.hasAssigned) {
        setIsDeleteError(false);
        setDeleteHeading(Context.langData.delete_tax_profile);
        setDeleteBodyMessage(Context.langData.delete_tax_profile_body);
      }
      dispatch(
        fetchTaxProfile(restId.rest_id, row.id, FetchTaxProfileCallback)
      );
    }
  }, [row]);

  const findRate = (taxId, taxItemList) => {
    const taxItem = taxItemList.find((item) => item.id === taxId);
    return taxItem ? taxItem.rate : null;
  };

  const handleInitialFormValues = (response, taxItemList) => {
    const newObj = {};

    response.forEach((item) => {
      if (item.id === 1) {
        newObj.MAX_AMOUNT = item.threshold;
      } else if (item.id === 2) {
        newObj.MIN_QUANTITY = item.threshold;
      }

      item.taxes.forEach((tax) => {
        const { rate } = tax;

        if (item.id === 1) {
          if (!newObj[tax.id]) newObj[tax.id] = {};
          newObj[tax.id].MAX_AMOUNT_RATE = rate;
        } else if (item.id === 2) {
          if (!newObj[tax.id]) newObj[tax.id] = {};
          newObj[tax.id].MIN_QUANTITY_RATE = rate;
        }
      });
    });

    taxItemList.forEach((taxItem) => {
      if (!newObj[taxItem.id]) newObj[taxItem.id] = {};

      if (
        newObj[taxItem.id].MAX_AMOUNT_RATE === undefined &&
        newObj.MAX_AMOUNT !== undefined
      ) {
        newObj[taxItem.id].MAX_AMOUNT_RATE = findRate(taxItem.id, taxItemList);
      }
      if (
        newObj[taxItem.id].MIN_QUANTITY_RATE === undefined &&
        newObj.MIN_QUANTITY !== undefined
      ) {
        newObj[taxItem.id].MIN_QUANTITY_RATE = findRate(
          taxItem.id,
          taxItemList
        );
      }
    });

    return newObj;
  };

  const FetchTaxProfileCallback = (response) => {
    if (response) {
      setId(response.id);
      setName(response.name);
      setComment(response.desc);
      const vss = [];
      response.taxes.forEach((vd) => {
        vss.push(vd.id);
      });
      setTaxesArray(vss);
      setTaxItemsArray(taxList.filter((item) => vss.includes(item.id)));
      const taxItemList = taxList.filter((item) => vss.includes(item.id));
      if (response.specialRules) {
        setSpecialRulesFlag(true);
        const initialFormValues = handleInitialFormValues(
          response.specialRules,
          taxItemList
        );
        setFormValues(initialFormValues);
      }
    }
  };

  const handleSave = () => {
    const taxIds = [];
    let errorFlag = 0;
    if (name.trim() !== "") {
      if (taxProfileList && taxProfileList.length > 0) {
        const prof = taxProfileList.filter(
          (e) => e.name.toLowerCase() === name.toLowerCase() && e.id !== id
        );
        if (prof.length > 0) {
          setNameError(Context.langData.tax_profile_exist);
          return;
        }
      }
      setNameError("");
    } else {
      errorFlag += 1;
      setNameError(Context.langData.no_text_error);
    }

    if (errorFlag === 0) {
      for (const i of taxesArray) {
        const vobj = {
          id: i,
        };
        taxIds.push(vobj);
      }
      if (taxIds.length > 0) {
        dispatch(
          editTaxProfile(
            restId.rest_id,
            id,
            {
              id,
              name,
              desc: comment,
              taxes: taxIds,
              specialRules: specialRulesFlag ? handleSpecialRules() : null,
            },
            editcallback
          )
        );
      } else {
        setTimeout(() => {
          const d = {
            open: true,
            severity: "error",
            msg: "select at least one tax",
          };
          setAlertData(d);
          setTimeout(() => {
            setAlertData({ open: false, severity: "success", msg: "" });
          }, 3000);
        }, 2000);
      }
    }
  };

  const NameChange = (value) => {
    setName(validateText(value));
    if (value) {
      setNameError("");
    }
  };

  const handleMultiSelect = (e) => {
    setTaxesArray(e);
    setTaxItemsArray(taxList.filter((item) => e.includes(item.id)));
  };

  const handleRateCheck = (idKey, rateKey) => {
    const matchingArrayObj = taxItemsArray.find((item) => item.id === idKey);
    return !(
      !matchingArrayObj || matchingArrayObj.rate === formValues[idKey][rateKey]
    );
  };

  const handleSpecialRules = () => {
    const outputArray = [];
    const maxAmount = parseFloat(formValues.MAX_AMOUNT);
    const minQuantity = parseFloat(formValues.MIN_QUANTITY);

    for (const key in formValues) {
      if (key !== "MAX_AMOUNT" && key !== "MIN_QUANTITY") {
        if (formValues[key].hasOwnProperty("MAX_AMOUNT_RATE")) {
          const taxes = [];
          const id = parseInt(key, 10);
          const maxAmountRate = parseFloat(formValues[key].MAX_AMOUNT_RATE);
          const maxAmountRateFlag = handleRateCheck(id, "MAX_AMOUNT_RATE");
          let arrayObject;

          const existingIndex = outputArray.findIndex((obj) => obj.id === 1);
          if (maxAmountRateFlag) {
            if (existingIndex !== -1) {
              if (maxAmountRate >= 0) {
                outputArray[existingIndex].taxes.push({
                  id,
                  rate: maxAmountRate,
                });
              }
            } else {
              if (maxAmountRate >= 0) {
                taxes.push({ id, rate: maxAmountRate });
              }

              arrayObject = {
                id: 1,
                threshold: maxAmount,
                taxes,
              };

              outputArray.push(arrayObject);
            }
          }
        }
        if (
          Object.prototype.hasOwnProperty.call(
            formValues[key],
            "MIN_QUANTITY_RATE"
          )
        ) {
          const taxes = [];
          const id = parseInt(key, 10);
          const minQuantityRate = parseInt(
            formValues[key].MIN_QUANTITY_RATE,
            10
          );
          const minQuantityRateFlag = handleRateCheck(id, "MIN_QUANTITY_RATE");
          let arrayObject;

          const existingIndex = outputArray.findIndex((obj) => obj.id === 2);
          if (minQuantityRateFlag) {
            if (existingIndex !== -1) {
              if (minQuantityRate >= 0) {
                outputArray[existingIndex].taxes.push({
                  id,
                  rate: minQuantityRate,
                });
              }
            } else {
              if (minQuantityRate >= 0) {
                taxes.push({ id, rate: minQuantityRate });
              }

              arrayObject = {
                id: 2,
                threshold: minQuantity,
                taxes,
              };

              outputArray.push(arrayObject);
            }
          }
        }
      }
    }
    return outputArray;
  };

  const handleThresholdChangeMaxAmount = (taxId, ruleCode, value) => {
    const overrideField = `${ruleCode}_RATE`;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      if (value === "") {
        handleInputChange(taxId, ruleCode, value);
      } else if (parseFloat(value) >= 0) {
        handleInputChange(taxId, ruleCode, value);
      }
    }
    taxItemsArray.map((item) => {
      if (!formValues[item.id]?.hasOwnProperty(overrideField)) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [item.id]: {
            ...prevFormValues[item.id],
            [overrideField]: item.rate,
          },
        }));
      }
    });
    if (value === "") {
      for (const taxIdKey in formValues) {
        delete formValues[taxIdKey][overrideField];
      }
    }
  };

  const handleThresholdChangeMinQuantity = (taxId, ruleCode, value) => {
    const overrideField = `${ruleCode}_RATE`;
    if (/^[1-9][0-9]*$/.test(value) || value === "") {
      if (value === "") {
        handleInputChange(taxId, ruleCode, value);
      } else if (parseInt(value, 10) >= 0) {
        handleInputChange(taxId, ruleCode, value);
      }
    }
    taxItemsArray.map((item) => {
      if (!formValues[item.id]?.hasOwnProperty(overrideField)) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [item.id]: {
            ...prevFormValues[item.id],
            [overrideField]: item.rate,
          },
        }));
      }
    });

    if (value === "") {
      for (const taxIdKey in formValues) {
        delete formValues[taxIdKey][overrideField];
      }
    }
  };

  const handleOverrideValueChange = (taxId, ruleCode, value) => {
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      if (value === "") {
        handleInputChange(taxId, `${ruleCode}_RATE`, value);
      } else if (parseFloat(value) >= 0 && parseFloat(value) <= 100) {
        handleInputChange(taxId, `${ruleCode}_RATE`, value);
      }
    }

    if (value === "" && ruleCode === "MAX_AMOUNT") {
      setMaxAmountErrors((prevErrors) => ({
        ...prevErrors,
        [taxId]:
          "Both taxable amount and override current tax value must be updated together",
      }));
      return;
    }
    if (value !== "" && ruleCode === "MAX_AMOUNT") {
      setMaxAmountErrors((prevErrors) => ({
        ...prevErrors,
        [taxId]: "",
      }));
    }

    if (value === "" && ruleCode === "MIN_QUANTITY") {
      setMinQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [taxId]:
          "Both quantity and override current tax value must be updated together",
      }));
    } else if (value !== "" && ruleCode === "MIN_QUANTITY") {
      setMinQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [taxId]: "",
      }));
    }
  };

  const handleTaxReductionCheckbox = () => {
    setSpecialRulesFlag((prev) => !prev);
  };

  const handleInputChange = (taxId, field, value) => {
    if (field === "MAX_AMOUNT" || field === "MIN_QUANTITY") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [field]: value,
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [taxId]: {
          ...prevFormValues[taxId],
          [field]: value,
        },
      }));
    }
  };

  // for Cancel and Delete button in delete dialog:
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteTaxProfile(restId.rest_id, row.id, deleteCallback));
    }
    setShowDialog(false);
  };

  const handleDelete = () => {
    setShowDialog(true);
  };

  return (
    <RightDrawer
      data-qaid='createTaxProfile_right_drawer'
      open={open}
      padding={1}>
      <>
        <Grid
          data-qaid='createTaxProfile_parent_grid'
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            data-qaid='createTaxProfile_body_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='createTaxProfile_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='createTaxProfile_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 30, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='assigntaxessettings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 30,
                marginBottom: 10,
              }}>
              <SettingsIcon
                data-qaid='assigntaxessetting_icon'
                style={{ marginRight: 10 }}
                width={16}
                height={16}
                color='#707272'
              />
              <H4
                data-qaid='assigntaxessettings'
                label={Context.langData.settings}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='createTaxProfile_title'
              style={{ marginLeft: 30, marginBottom: 20, marginRight: 30 }}
              label={Context.langData.create_tax_profile}
            />
            <Grid
              data-qaid='createTaxProfile_main_grid'
              style={{
                marginLeft: 30,
                marginBottom: 20,
                marginRight: 30,
                width: 530,
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                data-qaid='createTaxProfile_nameLabelView'
                style={{
                  marginTop: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <AvenirBlackH4
                  data-qaid='createTaxProfile_nameLabel'
                  label={Context.langData.name}
                />
                <InputText
                  value={name}
                  error={nameError}
                  maxLength={64}
                  onChange={(e) => {
                    NameChange(e.target.value);
                  }}
                  name='name'
                  id='name'
                />
              </Grid>
              <Grid
                data-qaid='createTaxProfile_comment_grid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 10,
                }}>
                <AvenirBlackH4
                  data-qaid='createTaxProfile_comlabel'
                  label={Context.langData.comments}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  data-qaid='createTaxProfile_comip'
                  value={comment}
                  maxLength={256}
                  onChange={(val) => setComment(val.target.value)}
                  id='comment'
                />
              </Grid>
              <Grid
                data-qaid='createTaxProfile_taxlistGrid'
                style={{ width: "100%", marginTop: 10 }}>
                <AvenirBlackH4
                  data-qaid='createTaxProfile_taxLabel'
                  label={Context.langData.tax_select_taxes}
                  style={{ marginTop: 20, paddingBottom: 10 }}
                />
                <MultiSelect
                  list={taxList}
                  value='id'
                  name='name'
                  placeholder='Select tax'
                  selectedValue={taxesArray}
                  handleChange={handleMultiSelect}
                  border={false}
                  height={56}
                  width={298}
                />
              </Grid>
              <Grid
                data-qaid='createTaxProfile_taxlistGrid'
                style={{ width: "100%", marginTop: 10 }}>
                <AvenirBlackH3
                  data-qaid='createTaxProfile_taxLabel'
                  label={Context.langData.tax_reduction}
                  style={{ marginTop: 20, paddingBottom: 10 }}
                />
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "space-between",
                    marginTop: "2%",
                  }}>
                  <CustomCheckbox
                    data-qaid='tax_profile_CustomCheckbox'
                    checked={specialRulesFlag}
                    marginLeft={0}
                    label={Context.langData.apply_tax_reduction}
                    onChange={handleTaxReductionCheckbox}
                  />
                </Grid>
              </Grid>
              {specialRulesFlag &&
                taxItemsArray?.map((taxItem, index) => (
                  <Grid style={{ width: "100%", marginTop: 10 }} key={index}>
                    <AvenirBlackH4
                      data-qaid='createTaxProfile_taxName'
                      label={`${taxItem.name} (${taxItem.value})`}
                      style={{ marginTop: 20, paddingBottom: 10 }}
                    />
                    <H4
                      data-qaid='createTaxProfile_by_taxable_amount'
                      label={Context.langData.by_taxable_amount}
                    />
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}>
                      <Grid
                        data-qaid='createTaxProfile_create_taxView'
                        style={{
                          marginTop: "10px",
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: 10,
                        }}>
                        <InputText
                          maxLength={6}
                          inputPlaceholder='Example: 4.01'
                          value={formValues.MAX_AMOUNT}
                          placeholder={Context.langData.taxable_amount_is_below}
                          onChange={(event) =>
                            handleThresholdChangeMaxAmount(
                              taxItem.id,
                              "MAX_AMOUNT",
                              event.target.value
                            )
                          }
                          name='MAX_AMOUNT_THRESHOLD'
                          type='number'
                        />
                      </Grid>
                      <Grid
                        data-qaid='createTaxProfile_nameLabelView'
                        style={{
                          marginTop: "10px",
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 10,
                        }}>
                        <InputText
                          min='0'
                          maxLength={6}
                          inputPlaceholder='Example: 5'
                          type='number'
                          name='MAX_AMOUNT_RATE'
                          error={maxAmountErrors[taxItem.id] || ""}
                          placeholder={
                            Context.langData.override_the_current_tax_value
                          }
                          onChange={(event) =>
                            handleOverrideValueChange(
                              taxItem.id,
                              "MAX_AMOUNT",
                              event.target.value
                            )
                          }
                          value={formValues[taxItem.id]?.MAX_AMOUNT_RATE}
                          endAdornment={
                            <InputAdornment
                              position='end'
                              style={{
                                color: "#051D33",
                              }}>
                              %
                            </InputAdornment>
                          }
                          colorStyle={{
                            color:
                              taxItem.rate ===
                              parseFloat(
                                formValues[taxItem.id]?.MAX_AMOUNT_RATE
                              )
                                ? "#707272"
                                : "#000",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <H4
                      data-qaid='createTaxProfile_by_taxable_amount'
                      label={Context.langData.by_quantity}
                      style={{ paddingTop: 5 }}
                    />
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}>
                      <Grid
                        data-qaid='createTaxProfile_nameLabelView'
                        style={{
                          marginTop: "10px",
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: 10,
                        }}>
                        <InputText
                          maxLength={6}
                          inputPlaceholder='Example: 6'
                          value={formValues.MIN_QUANTITY}
                          placeholder={Context.langData.quantity_is_more_than}
                          onChange={(event) =>
                            handleThresholdChangeMinQuantity(
                              taxItem.id,
                              "MIN_QUANTITY",
                              event.target.value
                            )
                          }
                          name='MIN_QUANTITY_THRESHOLD'
                          type='number'
                        />
                      </Grid>
                      <Grid
                        data-qaid='createTaxProfile_nameLabelView'
                        style={{
                          marginTop: "10px",
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 10,
                        }}>
                        <InputText
                          min='0'
                          maxLength={6}
                          inputPlaceholder='Example: 0'
                          type='number'
                          error={minQuantityErrors[taxItem.id] || ""}
                          name='MIN_QUANTITY_RATE'
                          placeholder={
                            Context.langData.override_the_current_tax_value
                          }
                          onChange={(event) =>
                            handleOverrideValueChange(
                              taxItem.id,
                              "MIN_QUANTITY",
                              event.target.value
                            )
                          }
                          value={formValues[taxItem.id]?.MIN_QUANTITY_RATE}
                          endAdornment={
                            <InputAdornment
                              position='end'
                              style={{
                                color: "#051D33",
                              }}>
                              %
                            </InputAdornment>
                          }
                          colorStyle={{
                            color:
                              taxItem.rate ===
                              parseFloat(
                                formValues[taxItem.id]?.MIN_QUANTITY_RATE
                              )
                                ? "#707272"
                                : "#000",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {taxProfileAssociations.find(
                (element) => element.id === row.id
              ) && (
                <Grid
                  data-qaid='createTaxProfile_taxlistGrid'
                  style={{ width: "100%", marginTop: 10 }}>
                  <AvenirBlackH3
                    data-qaid='createTaxProfile_taxLabel'
                    label={Context.langData.tax_profile_assignment}
                    style={{ marginTop: 20, paddingBottom: 20 }}
                  />
                  <Divider />
                  {taxProfileAssociations
                    .find((element) => element.id === row.id)
                    .categories.map((category, index) => (
                      <CategoryDropDownList
                        key={index}
                        categoryName={category.categoryName}
                        length={category.items.length}
                        itemList={category.items}
                      />
                    ))}
                  {taxProfileAssociations
                    .find((element) => element.id === row.id)
                    .items.map((item, index) => (
                      <Grid
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "space-between",
                          marginTop: "2%",
                          marginBottom: "2%",
                        }}>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "space-between",
                          }}>
                          <Grid
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "baseline",
                              padding: 5,
                            }}>
                            <CircleIcon
                              color='#007191'
                              width={8}
                              height={8}
                              style={{ marginRight: 15 }}
                            />
                            <H4
                              label={item.itemName}
                              style={{ fontWeight: "normal" }}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createTaxProfile_cancelButtonContainer'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                borderTop: "1px solid #C3C3C3",
                zIndex: 9999,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='shifts_delete_button'
                style={{ marginRight: 10 }}
                variant='text'
                id='cancel'
                onClick={handleDelete}>
                <DeleteIcon
                  data-qaid='shifts_deleteicon'
                  width={16}
                  height={16}
                  color='#8b0000'
                  style={{ marginRight: 5 }}
                />
                <AvenirRedH4
                  data-qaid='shifts_delete_label'
                  label={Context.langData.delete_tax_profile_button}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createTaxProfile_cancelButtonView'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 10,
                  width: 103,
                  height: 40,
                  border: "2px solid #051D33",
                }}
                variant='contained'
                id='cancel'
                onClick={() => onClose()}>
                <AvenirBlackH4
                  data-qaid='createTaxProfile_cancelButton'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createTaxProfile_saveButtonView'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 30,
                  width: 90,
                  height: 40,
                }}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={() => handleSave()}>
                <AvenirBlackH4
                  data-qaid='createTaxProfile_saveButtonLabel'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AlertDialogDelete
          data-qaid='offersList_alertdialog'
          showDialog={showDialog}
          body={deleteBodyMessage}
          heading={deleteHeading}
          isDeleteError={isDeleteError}
          handleClose={handleClose}
          showExtra={false}
          buttonLabel={Context.langData.delete}
        />
        <Message
          open={alertData.open}
          severity={alertData.severity}
          msg={alertData.msg}
          handleClose={() => {}}
        />
      </>
    </RightDrawer>
  );
};
const Message = ({ open, handleClose, severity, msg }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={severity}>
      <Typography variant='h5'>{msg}</Typography>
    </Alert>
  </Snackbar>
);
const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;
export default EditTaxProfile;
